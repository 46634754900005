import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

export default class AcademicProgramService {
    #api = null;

    constructor() {
        this.#api = API_URL + 'admin/academic-program';
    }
    paginate(data = {}, index = null) {
        let url = `${this.#api}`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param = {
            params: data
        }
        return apiService.query(url, param);
    }
    all() {
        let url = `${this.#api}/get/all`
        return apiService.get(url)
    }
    show(id) {
        let url = `${this.#api}/${id}`
        return apiService.get(url)
    }
    create(data) {
        let url = `${this.#api}`
        return apiService.post(url, data)
    }

    update(id, data) {
        let url = `${this.#api}/${id}`
        return apiService.post(url, data)
    }
    delete(examOptionId) {
        let url = `${this.#api}/${examOptionId}`
        return apiService.delete(url)
    }
    getByInstitution(institutionId, educationLevelId = '', session_id = '') {
        let url = `${this.#api}/${institutionId}/institution?education_level=${educationLevelId}&session_id=${session_id}`
        return apiService.get(url)
    }
    getAcademicProgramSession(programId) {
        let url = `${this.#api}/${programId}/get/all/session`;
        return apiService.get(url)
    }
    countAcademicProgram() {
        let url = `${this.#api}/get/count-program`
        return apiService.get(url)
    }
    updateCustomData(data) {
        let url = `${this.#api}/update/custom-data`;
        return apiService.post(url, data)
    }

    searchProgram(data) {
        let url = `${this.#api}/search/by/name`;
        let param = {
            params: data
        }
        return apiService.query(url, param);
    }
}
