import Vue from "vue";
import Router from "vue-router";
import {RouterTabRoutes} from 'vue-router-tab'
import GeneralRouter from "@/routes/GeneralRouter";

Vue.use(Router);

export default new Router({
    routes: [
        {
            path: "/",
            redirect: "/dashboard",
            component: () => import("@/view/layout/Layout"),
            children: [
                ...RouterTabRoutes,
                {
                    path: "/dashboard",
                    name: "dashboard",
                    component: () => import("@/view/pages/Dashboard.vue"),
                    meta: {
                        title: 'Dashboard',
                        icon: 'menu-icon flaticon2-architecture-and-city',
                        closable: false,
                        keepAlive: false,
                    }
                },
                ...GeneralRouter,
                {
                    path: "/user-agent",
                    name: "user-agent",
                    component: () => import("@/view/pages/view/user/agent/Index.vue"),
                    meta: {
                        title: 'Agents',
                        icon: 'menu-icon flaticon2-user',
                        closable: true
                    }
                },  {
                    path: "/user-agent/:id/detail",
                    name: "user-agent-profile",
                    component: () => import("@/view/pages/view/user/agent/Profile.vue"),
                    meta: {
                        title: 'Agent Profile',
                        icon: 'menu-icon flaticon2-user',
                        closable: true
                    }
                },
                {
                    path: "/user-student",
                    name: "user-student",
                    component: () => import("@/view/pages/view/user/student/Index.vue"),
                    meta: {
                        title: 'Candidates',
                        icon: 'menu-icon fas fa-user-graduate',
                        closable: true
                    }
                },
                {
                    path: "/future-lead-student",
                    name: "future-lead",
                    component: () => import("@/view/pages/view/user/onshore/Index.vue"),
                    meta: {
                        title: 'Onshore Candidate',
                        icon: 'menu-icon fas fa-users',
                        closable: true
                    }
                },
                {
                    path: "/candidate-inquiry-info",
                    name: "candidate-inquiry-info-create",
                    component: () => import("@/view/pages/view/user/onshore/CandidateInquiryInfo.vue"),
                    meta: {
                        title: 'Candidate inquiry info create',
                        icon: 'menu-icon fas fa-users',
                        closable: true
                    }
                },
                {
                    path: "/candidate-inquiry-info/:id",
                    name: "candidate-inquiry-info-edit",
                    component: () => import("@/view/pages/view/user/onshore/CandidateInquiryInfo.vue"),
                    meta: {
                        title: 'Candidate inquiry info edit',
                        icon: 'menu-icon fas fa-users',
                        closable: true
                    }
                },
                {
                    path: "/candidate/all",
                    name: "candidate-all",
                    component: () => import("@/view/pages/view/user/candidate/Index.vue"),
                    meta: {
                        title: 'All candidates',
                        icon: 'menu-icon fas fa-user-graduate',
                        closable: true
                    }
                },
                {
                    path: "/candidate/student",
                    name: "candidate-student",
                    component: () => import("@/view/pages/view/user/candidate/Index.vue"),
                    meta: {
                        title: 'Student candidates',
                        icon: 'menu-icon fas fa-user-graduate',
                        closable: true
                    }
                },
                {
                    path: "/user-request-for-agent",
                    name: "user-request-for-agent",
                    component: () => import("@/view/pages/view/user/request-for-agent/Index.vue"),
                    meta: {
                        title: 'Agent requests',
                        icon: 'menu-icon fas fa-user-graduate',
                        closable: true
                    }
                },          {
                    path: "/agent-received",
                    name: "agent-received",
                    component: () => import("@/view/pages/view/user/agent/AgentReceivedApplication.vue"),
                    meta: {
                        title: 'Pending approval agents',
                        icon: 'menu-icon fas fa-user-graduate',
                        closable: true
                    }
                },
                {
                    path: "/application-withdraw",
                    name: "application-withdraw",
                    component: () => import("@/view/pages/view/user/agent/applicant-withdraw/Index.vue"),
                    meta: {
                        title: 'Withdrawn agents',
                        icon: 'menu-icon fas fa-user-graduate',
                        closable: true
                    }
                },
                {
                    path: "testimonial",
                    name: "testimonial",
                    meta: {

                        title: 'Testimonials',
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/view/cms/testimonial/Index.vue"),
                },
                {
                    path: "contact-message",
                    name: "messages",
                    meta: {

                        title: 'Online inquiries',
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/view/cms/contact/Index.vue")
                },
                {
                    path: "address-book-category",
                    name: "address-book-category",
                    meta: {

                        title: 'Address book category',
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/view/address-book/category/Index.vue")
                },
                {
                    path: "testimonial/new",
                    name: "testimonial-new",
                    meta: {

                        title: 'Add testimonial',
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/view/cms/testimonial/CreateOrUpdate.vue")
                },

                {
                    path: "testimonial/:id",
                    name: "testimonial-update",
                    meta: {

                        title: 'Update testimonial',
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/view/cms/testimonial/CreateOrUpdate.vue")
                },
                {
                    path: "leave-category",
                    name: "leave-category",
                    meta: {

                        title: 'Leave category', // tab title
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true,
                        keepAlive: false// is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/view/leave/leave-category/Index.vue")
                },

                {
                    path: "leave",
                    name: "leave",
                    meta: {

                        title: 'Leave management', // tab title
                        tabClass: 'custom-tab', // custom class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true,
                        keepAlive: false// is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/view/leave/leave/Index.vue")
                },
                {
                    path: "/service",
                    name: "service",
                    component: () => import("@/view/pages/view/service/Index.vue"),
                    meta: {
                        title: 'Transaction headings',
                        icon: 'menu-icon fas fa-dollar',
                        closable: true
                    }
                },
                {
                    path: "/academic-class",
                    name: "academic-class",
                    component: () => import("@/view/pages/view/academic-class/Index.vue"),
                    meta: {
                        title: 'Academic class',
                        icon: 'menu-icon fas fa-school',
                        closable: true,
                        keepAlive: false,
                    }
                },
                {
                    path: "/academic/program-session",
                    name: "academic-program-session",
                    component: () => import("@/view/pages/view/academic/program/session/Index.vue"),
                    meta: {
                        title: 'Academic program session',
                        icon: 'menu-icon fas fa-business-time',
                        closable: true,
                        keepAlive: false,
                    }
                },
                {
                    path: "/academic-class/:classId/student",
                    name: "academic-class-student",
                    component: () => import("@/view/pages/view/academic-class/AcademicClassStudent.vue"),
                    meta: {
                        title: 'Class Summary',
                        icon: 'menu-icon fas fa-school',
                        closable: true,
                        keepAlive: false,
                    }
                },
                {
                    path: "/visa-application-status",
                    name: "visa-application-status",
                    component: () => import("@/view/pages/view/visa/application-status/Index.vue"),
                    meta: {
                        title: 'Visa Application Status',
                        icon: 'menu-icon fas fa-school',
                        closable: true
                    }
                },
                {
                    path: "/visa/:id/application-status-state",
                    name: "visa-application-status-state",
                    component: () => import("@/view/pages/view/visa/application-status/application-status-state/Index.vue"),
                    meta: {
                        title: 'Visa Application Status State ',
                        icon: 'menu-icon fas fa-school',
                        closable: true
                    }
                },

                {
                    path: "/resource-folder",
                    name: "resource-folder",
                    component: () => import("@/view/pages/view/cms/resource/folder/Index.vue"),
                    meta: {
                        title: 'Resource Folder',
                        icon: 'menu-icon fas fa-school',
                        closable: true
                    }
                },
                {
                    path: "/resource-file/:folderId",
                    name: "resource-file",
                    component: () => import("@/view/pages/view/cms/resource/file/Index.vue"),
                    meta: {
                        title: 'Resource Folder Files',
                        icon: 'menu-icon fas fa-school',
                        closable: true
                    }
                },
                {
                    path: "/lead-category",
                    name: "lead-category",
                    component: () => import("@/view/pages/view/enquiry/category/Index.vue"),
                    meta: {
                        title: 'Lead category',
                        icon: 'menu-icon fas fa-question-circle',
                        closable: true
                    }
                },
                {
                    path: "/enquiry",
                    name: "enquiry",
                    component: () => import("@/view/pages/view/enquiry/Index.vue"),
                    meta: {
                        title: 'Inquiry',
                        icon: 'menu-icon fas fa-question-circle',
                        closable: true
                    }
                },
                {
                    path: "/enquiry/create",
                    name: "enquiry-create",
                    component: () => import("@/view/pages/view/enquiry/CreateAndUpdate.vue"),
                    meta: {
                        title: 'Add inquiry',
                        icon: 'menu-icon fas fa-question-circle',
                        closable: true
                    }
                },
                {
                    path: "/enquiry/:enquiryId/edit",
                    name: "enquiry-edit",
                    component: () => import("@/view/pages/view/enquiry/CreateAndUpdate.vue"),
                    meta: {
                        title: 'Edit inquiry',
                        icon: 'menu-icon fas fa-question-circle',
                        closable: true
                    }
                },
                {
                    path: "/followup/:enquiryId",
                    name: "followup",
                    component: () => import("@/view/pages/view/enquiry/enquiry-followup/Index.vue"),
                    meta: {
                        title: 'Inquiry followup',
                        icon: 'menu-icon fas fa-question-circle',
                        closable: true
                    }
                },
                {
                    path: "/user-profile",
                    name: "profile",
                    component: () => import("@/view/pages/user/profile/Index.vue"),
                    meta: {
                        title: 'Profile',
                        icon: 'menu-icon flaticon2-user',
                        closable: true
                    }

                },
                {
                    path: "/unauthorized",
                    name: "unauthorized",
                    component: () => import("@/view/pages/error/unauthorized.vue"),
                    meta: {
                        title: 'Unauthorized',
                        icon: 'menu-icon flaticon2-user',
                        closable: true
                    }
                },
                {
                    path: "/attributes",
                    name: "attributes",
                    component: () => import("@/view/pages/view/academic/attribute/Index.vue"),
                    meta: {
                        title: 'Attributes',
                        icon: "menu-icon fas fa-setting",
                    }
                },

                {
                    path: "/institutions",
                    name: "institutions",
                    component: () => import("@/view/pages/view/academic/institution/Index.vue"),
                    meta: {
                        title: 'Institutions',
                        icon: "menu-icon fas fa-university",
                        keepAlive: false,
                    }
                },
                {
                    path: "/search",
                    name: "search",
                    component: () => import("@/view/pages/view/search/Search.vue"),
                    meta: {
                        title: 'Search',
                        icon: "menu-icon fas fa-search",

                    }
                },
                {
                    path: "/institutions/:institution_id/edit",
                    name: "institutions-edit",
                    component: () => import("@/view/pages/view/academic/institution/CreateAndUpdate.vue"),
                    meta: {
                        title: 'Update Institutions',
                        icon: 'fas fa-pencil-alt',
                        key: 'fullPath',

                    }
                },
                {
                    path: "/institutions/create",
                    name: "institutions-create",
                    component: () => import("@/view/pages/view/academic/institution/CreateAndUpdate.vue"),
                    meta: {
                        title: 'Add Institutions',
                        icon: 'fas fa-plus',

                    }
                },
                {
                    path: "/grading-scheme",
                    name: "grading-scheme",
                    component: () => import("@/view/pages/view/academic/grading-scheme/Index.vue"),
                    meta: {
                        icon: 'fas fa-bars',
                        title: 'Grading Scheme',

                    }
                },
                {
                    path: "/program",
                    name: "program",
                    component: () => import("@/view/pages/view/academic/program/Index.vue"),
                    meta: {
                        title: 'Program',
                        icon: "menu-icon fas fa-school",

                    }
                },
                {
                    path: "/program/:program_id/edit",
                    name: "program-edit",
                    component: () => import("@/view/pages/view/academic/program/CreateAndUpdate.vue"),
                    meta: {
                        title: 'Update Program',
                        icon: 'fas fa-pencil-alt',
                        key: 'fullPath',

                    }
                },
                {
                    path: "/program/create",
                    name: "program-create",
                    component: () => import("@/view/pages/view/academic/program/CreateAndUpdate.vue"),
                    meta: {
                        title: 'Add Program',
                        icon: 'fas fa-plus',

                    }
                },
                {
                    path: "/academic-program/category/create",
                    name: "academic-program-category",
                    component: () => import("@/view/pages/view/academic/program/category/Index.vue"),
                    meta: {
                        title: 'Study Areas',
                        icon: 'fas fa-cogs',

                    }
                },
                {
                    path: "/application/:type",
                    name: "search-application",
                    component: () => import("@/view/pages/view/application/Index.vue"),
                    meta: {
                        title: 'Search Application',
                        icon: 'fas fa-file',
                    }
                },
                {
                    path: "/application/:type/:status",
                    name: "applications",
                    component: () => import("@/view/pages/view/application/Index.vue"),
                    meta: {
                        title: 'Application',
                        icon: 'fas fa-file',

                    }
                },
                {
                    path: "/application-detail/:id",
                    name: "application-detail",
                    component: () => import("@/view/pages/view/application/Detail.vue"),
                    meta: {
                        title: 'Application Detail',
                        icon: 'fas fa-file',
                    }
                },
                {
                    path: "/state/:country_id",
                    name: "state",
                    component: () => import("@/view/pages/view/country/state/Index.vue"),
                    meta: {
                        title: 'State',
                        icon: "menu-icon fas fa-school",

                    }
                },
                {
                    path: "/city/:country_id/:state_id",
                    name: "city",
                    component: () => import("@/view/pages/view/country/city/Index.vue"),
                    meta: {
                        title: 'City',
                        icon: "menu-icon fas fa-school",

                    }
                },
                {
                    path: "/countries",
                    name: "countries",
                    component: () => import("@/view/pages/view/country/Index.vue"),
                    meta: {
                        title: 'Country',
                        icon: "menu-icon fas fa-globe",

                    }
                },
                {
                    path: "/education-level",
                    name: "education-level",
                    component: () => import("@/view/pages/view/academic/education-level/Index.vue"),
                    meta: {
                        title: 'Education Level',
                        icon: "menu-icon fas fa-school",

                    }
                },
                {
                    path: "/institution-type",
                    name: "institution-type",
                    component: () => import("@/view/pages/view/academic/institution-type/Index.vue"),
                    meta: {
                        title: 'Institution Type',
                        icon: "menu-icon fas fa-school",

                    }
                },
                {
                    path: "/finance",
                    name: "finance",
                    component: () => import("@/view/pages/view/user/student/profile/components/payment/PaymentList.vue"),
                    meta: {
                        title: 'Finance',
                        icon: "menu-icon mdi mdi-finance",

                    }
                },
                {
                    path: "/task-manager",
                    name: "task-manager",
                    component: () => import("@/view/pages/view/task/Task"),
                    meta: {
                        title: 'Task',
                        icon: 'menu-icon fas fa-tasks',
                        key: 'fullPath',
                        keepAlive: false,
                    }
                },
                {
                    path: "/task-manager/create",
                    name: "create-task-manager",
                    component: () => import("@/view/pages/view/task/TaskDetail"),
                    meta: {
                        title: 'Create Task',
                        icon: 'menu-icon fas fa-tasks',
                        key: 'fullPath',
                        keepAlive: false,
                    }
                },
                {
                    path: "/task-manager/:taskId/edit",
                    name: "edit-task-manager",
                    component: () => import("@/view/pages/view/task/TaskDetail"),
                    meta: {
                        title: 'Edit Task',
                        icon: 'menu-icon fas fa-tasks',
                        key: 'fullPath',
                        keepAlive: false,
                    }
                },

                {
                    path: "/student/:user_id",
                    name: "student-profile",
                    component: () => import("@/view/pages/view/user/student/profile/Index.vue"),
                    meta: {
                        title: 'Student',
                        icon: "menu-icon fas fa-school",

                    }
                },
                {
                    path: "/admin-user",
                    name: "admin-user",
                    component: () => import("@/view/pages/view/admin-user/Index.vue"),
                    meta: {
                        title: 'System Users',
                        icon: 'fa fa-user',

                    }
                },
                {
                    path: "/subscription",
                    name: "user-subscription",
                    component: () => import("@/view/pages/view/subscription/Index.vue"),
                    meta: {
                        title: 'Subscription',
                        icon: 'fa fa-user',

                    }
                },
                {
                    path: "/appointments",
                    name: "appointments",
                    component: () => import("@/view/pages/view/appointment/Index.vue"),
                    meta: {
                        title: 'Appointments',
                        icon: 'fa fa-calendar',

                    }
                },
                {
                    path: "/my-appointments",
                    name: "my-appointments",
                    component: () => import("@/view/pages/view/my-appointment/Index.vue"),
                    meta: {
                        title: 'My appointments',
                        icon: 'fa fa-calendar',

                    }
                },
                {
                    path: "/admin-user/:admin_user_id/profile",
                    name: "admin-user-profile",
                    component: () => import("@/view/pages/view/admin-user/profile/Index.vue"),
                    meta: {
                        title: 'Profile admin ',
                        icon:'fa fa-user',

                    }
                },
                {
                    path: "/slider",
                    name: "slider",
                    component: () => import("@/view/pages/view/cms/slider/Index.vue"),
                    meta: {
                        title: 'Slider',
                        icon:'fas fa-image',

                    }
                },
                {
                    path: "/sliders/:sliderId/edit",
                    name: "sliders-edit",
                    component: () => import("@/view/pages/view/cms/slider/CreateOrUpdate.vue"),
                    meta: {
                        title: 'Update slider',
                        icon:'fas fa-pencil-alt',
                        key: 'fullPath',

                    }
                },
                {
                    path: "/sliders/create",
                    name: "sliders-create",
                    component: () => import("@/view/pages/view/cms/slider/CreateOrUpdate.vue"),
                    meta: {
                        title: 'Add slider',
                        icon:'fas fa-plus',

                    }
                },
                {
                    path: "/slider-type",
                    name: "slider-type",
                    component: () => import("@/view/pages/view/cms/slider/slidertype/Index.vue"),
                    meta: {
                        title: 'Slider type',
                        icon:'fas fa-plus',

                    }
                },
                {
                    path: "/faq",
                    name: "faq",
                    component: () => import("@/view/pages/view/cms/faq/Index.vue"),
                    meta: {
                        title: 'FAQ',
                        icon:'fas fa-question-circle',

                    }
                },
                {
                    path: "/faq-category",
                    name: "faq-category",
                    component: () => import("@/view/pages/view/cms/faq/category/Index.vue"),
                    meta: {
                        title: 'FAQ category',
                        icon:'fas fa-question-circle',

                    }
                },
                {
                    path: "/popup",
                    name: "popup",
                    component: () => import("@/view/pages/view/cms/popup/Index.vue"),
                    meta: {
                        icon: 'fas fa-external-link-alt',
                        title: 'Popup',
                    }
                },
                {
                    path: "/media",
                    name: "media",
                    component: () => import("@/view/pages/view/cms/media/Index.vue"),
                    meta: {
                        icon: 'fas fa-file',
                        title: 'Media',

                    }
                },
                {
                    path: "/download",
                    name: "download",
                    component: () => import("@/view/pages/view/cms/download/Index.vue"),
                    meta: {
                        icon: 'fas fa-download',
                        title: 'Download',

                    }
                },
                {
                    path: "/download-category",
                    name: "download-category",
                    component: () => import("@/view/pages/view/cms/download-category/Index.vue"),
                    meta: {
                        icon: 'fas fa-download',
                        title: 'Download category',

                    }
                },
                {
                    path: "/email-response",
                    name: "email-response",
                    component: () => import("@/view/pages/view/cms/email-response/Index.vue"),
                    meta: {
                        icon: 'fas fa-bars',
                        title: 'Predefined responses',

                    }
                },
                {
                    path: "/change-request",
                    name: "change-request",
                    component: () => import("@/view/pages/view/user/agent/change-request/Index.vue"),
                    meta: {
                        icon: 'fas fa-bars',
                        title: 'Agent information update request',
                    }
                },
                {
                    path: "/reminder-note",
                    name: "reminder-note",
                    component: () => import("@/view/pages/view/notes/Notes.vue"),
                    meta: {
                        icon: 'fas fa-note-sticky',
                        title: 'Note reminder',
                    }
                },
                {
                    path: "menu-manager",
                    name: "menu-manager",
                    meta: {
                        icon: 'fas fa-bars',
                        title: 'Menu Manager',
                        tabClass: 'custom-tab', // custom academic-class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/view/cms/menu-manager/menu/Index.vue")
                },
                {
                    path: "menu-manager/:id/menu",
                    name: "menu-manager-menu",
                    meta: {
                        icon: 'fas fa-bars',
                        title: 'Edit menu',
                        tabClass: 'custom-tab', // custom academic-class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/view/cms/menu-manager/Index.vue")
                },
                {
                    path: "setting",
                    name: "setting",
                    meta: {
                        title: 'Settings',
                        tabClass: 'custom-tab', // custom academic-class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/view/cms/setting/Index.vue")

                },
                {
                    path: "/blog",
                    name: "blog",
                    component: () => import("@/view/pages/view/cms/blog/Index.vue"),
                    meta: {
                        title: 'Blog',
                        icon: "menu-icon fas fa-bars",

                    }
                },
                {
                    path: "/blog/:blogId/edit",
                    name: "blog-edit",
                    component: () => import("@/view/pages/view/cms/blog/CreateAndUpdate.vue"),
                    meta: {
                        title: 'Update blog',
                        icon:'fas fa-pencil-alt',
                        key: 'fullPath',

                    }
                },
                {
                    path: "/blog/create",
                    name: "blog-create",
                    component: () => import("@/view/pages/view/cms/blog/CreateAndUpdate.vue"),
                    meta: {
                        title: 'Add blog',
                        icon:'fas fa-plus',

                    }
                },
                {
                    path: "/blog-category",
                    name: "blog-category",
                    component: () => import("@/view/pages/view/cms/blog/category/Index.vue"),
                    meta: {
                        icon: "menu-icon fas fa-bars",
                        title: 'Blog category',

                    }
                },
                {
                    path: "/branch",
                    name: "branch",
                    component: () => import("@/view/pages/view/branch/Index.vue"),
                    meta: {
                        icon: 'fas fa-warehouse',
                        title: 'Branch',

                    }
                },
                {
                    path: "/page",
                    name: "page",
                    component: () => import("@/view/pages/view/cms/page/Index.vue"),
                    meta: {
                        title: 'Page',
                        icon: 'fas fa-copy',
                        key: 'fullPath',

                    }
                },
                {
                    path: "/page/create",
                    name: "page-create",
                    component: () => import("@/view/pages/view/cms/page/CreateAndUpdate.vue"),
                    meta: {
                        title: 'Add Page',
                        icon: 'fas fa-copy',
                        key: 'fullPath',

                    }
                },
                {
                    path: "/page/:id/edit",
                    name: "page-edit",
                    component: () => import("@/view/pages/view/cms/page/CreateAndUpdate.vue"),
                    meta: {
                        title: 'Edit Page',
                        icon: 'fas fa-copy',
                        key: 'fullPath',

                    }
                },
                {
                    path: "/setting/general",
                    name: "general-setting",
                    component: () => import("@/view/pages/view/cms/setting/Option/Index.vue"),
                    meta: {
                        title: 'General settings',
                        icon: 'fas fa-hammer',
                        key: 'fullPath',

                    }
                },
                {
                    path: "/setting/maintenance",
                    name: "setting-maintenance",
                    component: () => import("@/view/pages/view/cms/setting/Maintenance.vue"),
                    meta: {
                        title: 'Site maintenance',
                        icon: 'fas fa-wrench',
                        key: 'fullPath',

                    }
                },

                {
                    path: "/notice/",
                    name: "notice",
                    component: () => import("@/view/pages/view/cms/notice/Index.vue"),
                    meta: {
                        title: 'Notices',
                        key: 'fullPath',
                        icon: 'fas fa-bullhorn',

                    }
                },
               {
                    path: "email-template/:role",
                    name: "email-template",
                    meta: {

                        title: 'Email Templates', // tab title
                        tabClass: 'custom-tab', // custom academic-class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true,
                        keepAlive: false// is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/view/email-template/Index.vue")
                },
                {
                    path: "email-template-roles",
                    name: "email-template-roles",
                    meta: {

                        title: 'Email Templates Roles', // tab title
                        tabClass: 'custom-tab', // custom academic-class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true,
                        keepAlive: false// is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/view/email-template/Roles.vue")
                },
                {
                    path: "email-template/:id/update",
                    name: "email-template-update",
                    meta: {

                        title: 'Edit Email Template', // tab title
                        tabClass: 'custom-tab', // custom academic-class, optional
                        key: 'path', // tab cache rule, optional
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/view/email-template/CreateAndUpdate.vue")
                },
                {
                    path: "/category",
                    name: "category",
                    component: () => import("@/view/pages/view/application/discussion/category/Index.vue"),
                    meta: {
                        title: 'Application Discussion Category',
                        key: 'fullPath',
                        icon: 'fas fa-clipboard-check',
                        keepAlive: false,
                    }
                },
                {
                    path: "/discussion/:discussionId/view",
                    name: "discussion-view",
                    component: () => import("@/view/pages/view/user/student/profile/components/application-discussion/View.vue"),
                    meta: {
                        title: 'View Discussion',
                        icon:'fas fa-comment',
                        key: 'fullPath',
                        keepAlive: false,
                    }
                },
                {
                    path: "/discussion/:category_slug",
                    name: "application-discussion",
                    component: () => import("@/view/pages/view/application/discussion/Index.vue"),
                    meta: {
                        title: 'Discussion ',
                        key: 'fullPath',
                        icon: 'fas fa-comment-alt',
                        keepAlive: false,
                    }
                },

                {
                    path: "/discussion/closed",
                    name: "discussion-closed",
                    component: () =>  import("@/view/pages/view/application/discussion/DiscussionClosed.vue"),
                    meta: {
                        title: 'Closed Discussion',
                        icon:'fas fa-comment-alt',
                        key: 'fullPath',
                        keepAlive: false,
                    }
                },
                //AddressBook
                {
                    path: "/address-book",
                    name: "address-book",
                    component: () => import("@/view/pages/view/address-book/Index.vue"),
                    meta: {
                        title: 'Address book',
                        key: 'fullPath',
                        icon: 'fas fa-address-book',

                    }
                },
                {
                    path: "/address-book/create",
                    name: "address-book-create",
                    component: () => import("@/view/pages/view/address-book/CreateOrUpdate.vue"),
                    meta: {
                        title: 'Add contact',
                        icon: 'fas fa-plus',

                    }
                },
                {
                    path: "/address-book/:addressBookId/edit",
                    name: "address-book-edit",
                    component: () => import("@/view/pages/view/address-book/CreateOrUpdate.vue"),
                    meta: {
                        title: 'Update contact',
                        icon: 'fas fa-pencil-alt',
                        key: 'fullPath',

                    }
                },
                {
                    path: "/required-document",
                    name: "required-document",
                    component: () => import("@/view/pages/view/required-document/Index.vue"),
                    meta: {
                        title: 'Required Document',
                        icon: "menu-icon fas fa-school",

                    }
                },
                {
                    path: "/required-document/:id/group",
                    name: "required-document-value",
                    component: () => import("@/view/pages/view/required-document/value/Index.vue"),
                    meta: {
                        title: 'Required Document Group',
                        icon: "menu-icon fas fa-school",

                    }
                },
                {
                    path: "/skill-occupation",
                    name: "skill-occupation",
                    component: () => import("@/view/pages/view/skill-occupation/Index.vue"),
                    meta: {
                        title: 'Skill Occupation',
                        icon: "menu-icon fas fa-school",

                    }
                },
                {
                    path: "/visa-type",
                    name: "visa-type",
                    component: () => import("@/view/pages/view/visa/type/Index.vue"),
                    meta: {
                        title: 'Visa Type',
                        icon: "menu-icon fas fa-school",

                    }
                },
                {
                    path: "/application-type",
                    name: "application-type",
                    component: () => import("@/view/pages/view/application/type/Index.vue"),
                    meta: {
                        title: 'Application Type',
                        icon: "menu-icon fas fa-school",

                    }
                },
                {
                    path: "/nomination-category",
                    name: "nomination-category",
                    component: () => import("@/view/pages/view/nomination/nomination-category/Index.vue"),
                    meta: {
                        title: 'Nomination Category',
                        icon: "menu-icon fas fa-school",

                    }
                },
                {
                    path: "/nomination-subclass",
                    name: "nomination-subclass",
                    component: () => import("@/view/pages/view/nomination/nomination-subclass/Index.vue"),
                    meta: {
                        title: 'Nomination Subclass',
                        icon: "menu-icon fas fa-school",

                    }
                },
                {
                    path: "/nomination-application",
                    name: "nomination-application",
                    component: () => import("@/view/pages/view/nomination/nomination-application/Index.vue"),
                    meta: {
                        title: 'Nomination Application',
                        icon: "menu-icon fas fa-school",

                    }
                },
                {
                    path: "/update-requests/application-deferred",
                    name: "application-deferred-update-requests",
                    component: () => import("@/view/pages/view/update-request/application/Index.vue"),
                    meta: {
                        title: 'Application Deferred',
                        key: 'fullPath',
                        icon: 'fas fa-user-edit',

                    }
                },
                {
                    path: "/update-requests/application-refund",
                    name: "application-refund-update-requests",
                    component: () => import("@/view/pages/view/update-request/refund/Index.vue"),
                    meta: {
                        title: 'Application Deferred',
                        key: 'fullPath',
                        icon: 'fas fa-user-edit',

                    }
                },
                {
                    path: "/update-requests/archived",
                    name: "archived-update-requests",
                    component: () => import("@/view/pages/view/update-request/archived/Index.vue"),
                    meta: {
                        title: 'Application Achieved',
                        key: 'fullPath',
                        icon: 'fas fa-user-edit',

                    }
                },
                {
                    path: "/purpose-for-visit",
                    name: "purpose-for-visit",
                    component: () => import("@/view/pages/view/purpose-for-visit/Index.vue"),
                    meta: {
                        title: 'Purpose for visit',
                        key: 'fullPath',
                        icon: 'fas fa-user-edit',

                    }
                },
                {
                    path: "finance",
                    name: "finance-base",
                    meta: {
                        title: 'Account & finance',
                        icon:'fas fa-money-check',
                        key: 'fullPath',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/view/finance/Base.vue"),
                    children: [

                        {
                            path: "transaction-head",
                            name: "finance-transaction-head",
                            meta: {

                                title: 'Transaction headings',
                                icon:'fas fa-file-invoice-dollar',
                                tabClass: 'custom-tab', // custom class, optional
                                key: 'fullPath', // tab cache rule, optional
                                closable: true // is tab closable, defaults to `true`
                            },
                            component: () => import("@/view/pages/view/finance/transaction-heads/Index.vue")
                        },
                        {
                            path: "payment-type",
                            name: "payment-type",
                            meta: {

                                title: 'Finance summary',
                                key: 'fullPath',
                                icon:'fas fa-file-invoice-dollar',
                                closable: true // is tab closable, defaults to `true`
                            },
                            component: () => import("@/view/pages/view/finance/payment-type/Index.vue")
                        },
                        {
                            path: "transaction/records",
                            name: "finance-history",
                            meta: {

                                title: 'Transactions history',
                                key: 'fullPath',
                                closable: true // is tab closable, defaults to `true`
                            },
                            component: () => import("@/view/pages/view/finance/history/Index.vue")
                        }, {
                            path: "create/:type",
                            name: "finance-history-type",
                            meta: {

                                title: ' Add transaction',
                                key: 'fullPath',
                                closable: true // is tab closable, defaults to `true`
                            },
                            component: () => import("@/view/pages/view/finance/history/CreateOrUpdate.vue")
                        },
                        {
                            path: "update/:type/update/:id",
                            name: "finance-history-type-update",
                            meta: {

                                title: ' Update transaction',
                                key: 'fullPath',
                                closable: true // is tab closable, defaults to `true`
                            },
                            component: () => import("@/view/pages/view/finance/history/CreateOrUpdate.vue")
                        },
                        {
                            path: "transaction/view/:id/invoice",
                            name: "finance-history-invoice",
                            meta: {

                                title: 'Invoice',
                                key: 'fullPath',
                                closable: true // is tab closable, defaults to `true`
                            },
                            component: () => import("@/view/pages/view/finance/history/invoice/Index.vue")
                        },

                    ]
                },
                {
                    path: "invoice",
                    name: "invoice",
                    meta: {
                        title: 'Invoice',
                        icon:'fas fa-file-invoice',
                        key: 'fullPath',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/view/invoice/Index.vue")
                },
                {
                    path: "invoice/create",
                    name: "invoice-create",
                    meta: {
                        title: 'Create invoice ',
                        icon:'fas fa-file-invoice',
                        key: 'fullPath',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/view/invoice/CreateOrUpdate.vue")
                },
                {
                    path: "invoice/:id/view",
                    name: "invoice-view",
                    meta: {
                        title: 'View invoice ',
                        icon:'fas fa-file-invoice',
                        key: 'fullPath',
                        closable: true // is tab closable, defaults to `true`
                    },
                    component: () => import("@/view/pages/view/invoice/ViewInvoice.vue")
                },
                {
                    path: "403",
                    name: "403",
                    component: () => import("@/view/pages/error/Error-1.vue"),
                    meta: {
                        title: '403',
                        key: 'fullPath',
                        icon: 'fas fa-warning',
                    }
                },
            ],
        },
        {
            path: "/error",
            name: "error",
            component: () => import("@/view/pages/error/Error.vue"),
            children: [
                {
                    path: "error-2",
                    name: "error-2",
                    component: () => import("@/view/pages/error/Error-2.vue")
                },
                {
                    path: "error-3",
                    name: "error-3",
                    component: () => import("@/view/pages/error/Error-3.vue")
                },
                {
                    path: "error-4",
                    name: "error-4",
                    component: () => import("@/view/pages/error/Error-4.vue")
                },
                {
                    path: "error-5",
                    name: "error-5",
                    component: () => import("@/view/pages/error/Error-5.vue")
                },
                {
                    path: "error-6",
                    name: "error-6",
                    component: () => import("@/view/pages/error/Error-6.vue")
                },

            ]
        },
        {
            path: "/",
            component: () => import("@/view/pages/auth/Auth"),
            children: [
                {
                    name: "login",
                    path: "/login",
                    component: () => import("@/view/pages/auth/Login")
                },
                {
                    name: "register",
                    path: "/register",
                    component: () => import("@/view/pages/auth/Register")
                },
                {
                    name: "verification",
                    path: "/verification",
                    component: () => import("@/view/pages/auth/Verification")
                },
            ]
        },
        {
            path: "*",
            redirect: "/404"
        },
        {
            // the 404 route, when none of the above matches
            path: "/404",
            name: "404",
            component: () => import("@/view/pages/error/Error-1.vue")
        },

    ]
});
