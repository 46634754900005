import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

export default class UserService {
    #api = null;

    constructor() {
        this.#api = API_URL + 'admin/user';
    }
    all() {
        let url = `${this.#api}/get/all`;
        return apiService.get(url);
    }

    getAllByUserType(data = {}) {
        let url = `${this.#api}/get/all`;
        let param = {
            params: data
        }
        return apiService.query(url, param);
    }

    paginate(data = {}, index = null) {
        let url = `${this.#api}`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param = {
            params: data
        }
        return apiService.query(url, param);
    }
    create(data) {

        let url = `${this.#api}`;
        return apiService.post(url, data);
    }
    update(id, data) {
        let url = `${this.#api}/${id}`;
        return apiService.post(url, data)
    }
    updateBranch(id, data) {
        let url = `${this.#api}/${id}/update-branch`;
        return apiService.put(url, data)
    }
    show(id) {
        let url = `${this.#api}/${id}`
        return apiService.get(url)
    }
    delete(id) {
        let url = `${this.#api}/${id}`
        return apiService.delete(url);
    }
    resetPassword(id, data) {
        let url = `${this.#api}/${id}/reset-password`;
        return apiService.post(url, data);
    }
    deactivateAccount(id) {
        let url = `${this.#api}/${id}/deactivate`;
        return apiService.get(url)
    }
    checkDuplicateEmail(data = {}) {
        let url = `${this.#api}/check/duplicate-email`;

        return apiService.post(url, data);
    }
    checkDuplicateEmailForUpdate(id, data = {}) {
        let url = `${this.#api}/${id}/check/duplicate-email`;

        return apiService.post(url, data);
    }
    assignRole(id, data) {
        let url = `${this.#api}/${id}/assign-role`
        return apiService.post(url, data);
    }
    removeRole(id, data) {
        let url = `${this.#api}/${id}/remove-role`
        return apiService.post(url, data);
    }
    userRoles(id) {
        let url = `${this.#api}/${id}/roles`
        return apiService.get(url);
    }

    getAppointments(userId, data = {}, index = null) {
        let url = `${this.#api}/${userId}/appointment`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param = {
            params: data
        }
        return apiService.query(url, param);
    }

    getUpcomingAppointment(userId){
        let url = `${this.#api}/${userId}/upcoming-appointment`;
        return apiService.get(url);
    }

    getAppointmentsCount(userId){
        let url = `${this.#api}/${userId}/appointment/get-totals`
        return apiService.get(url)
    }

    getAllRequestUser(index = null) {
        let url = `${this.#api}/get/request-user`;
        if (index != null)
            url = `${url}?page=${index}`;
        return apiService.query(url);
    }

    approvedUser(id, data) {
        let url = `${this.#api}/approved-user/${id}`;
        return apiService.post(url, data)
    }

    declinedUser(id, data) {
        let url = `${this.#api}/declined-user/${id}`;
        return apiService.post(url, data)
    }

    manageSubscription(id,data)
    {
        let url = `${this.#api}/${id}/manage-subscription`;
        return apiService.post(url,data)
    }

    getTotalApplicants()
    {
        let url = `${this.#api}/get/count-applicant`;
        return apiService.get(url)
    }

    makeAgent(id)
    {
        let url = `${this.#api}/${id}/make-agent`;
        return apiService.post(url)
    }

    search(data={}){
        let url = `${this.#api}/search/student`;
        let param = {
            params: data
        }
        return apiService.query(url, param);
    }

    getUserAgent(userId){
        let url = `${this.#api}/${userId}/get-agent`;
        return apiService.get(url);
    }

    removeAgent(userId, data) {
        let url = `${this.#api}/${userId}/remove-agent`;
        return apiService.post(url, data)
    }

    getAgents(data={}){
        let url = `${this.#api}/get-agents`;
        let param = {
            params: data
        }
        return apiService.query(url, param);
    }

    assignAgent(userId, data={}){
        let url = `${this.#api}/${userId}/assign-agent`;
        return apiService.post(url, data)
    }

    loginAsAgent(id)
    {
        let url = `${this.#api}/login-as/${id}/agent`;
        return apiService.post(url)
    }

    deactivateOrActivateAgent(id, data)
    {
        let url = `${this.#api}/${id}/activate-deactivate/agent`;
        return apiService.post(url, data)
    }

    updatePassword(id, data){
        let url = `${this.#api}/${id}/change-password`
        return apiService.put(url,data)
    }

    getAllReferralUsers(userId, data = {}, index = null) {
        let url = `${this.#api}/${userId}/get/referral-users/all`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param = {
            params: data
        }
        return apiService.query(url, param);
    }
}
